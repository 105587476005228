import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

const ImageComment = `
	id
	name
	firstName
	insertedOn
	picture
	imageChildId
	message
	doLike
	doNotLike
	xPos
	yPos
	pinInfoCode
`;

/**
 * @param {Object} comment - {xid: String, message: String, like: Boolean}
 */
export const addImgComment = async ({ xid, message, like, pos = null, docDeliId = 0 }) => {
	if (isNil(xid)) return;
	const l = isNil(like) ? false : like;

	let payload = {
		Xid: xid,
		Message: message || '',
		[l ? 'DoLike' : 'DoNotLike']: l ? 1 : 0,
		DocDeliId: parseInt(docDeliId, 10)
	};

	if (!isNil(pos)) {
		payload = {
			...payload,
			XPos: parseFloat(pos.x),
			YPos: parseFloat(pos.y),
			Code: parseInt(pos.code, 10)
		};
	}

	try {
		const mutation = gql`
			mutation(
				$Xid: String!,
				$Message: String,
				$DoLike: Int,
				$DoNotLike: Int,
				$DocDeliId: ID,

				${!isNil(pos) ? '$XPos: Float, $YPos: Float, $Code: ID' : ''}
			) {
				AddItemComment(
				Xid: $Xid
				Message: $Message
				DoLike: $DoLike
				DoNotLike: $DoNotLike
				DocDeliId: $DocDeliId
					${
						!isNil(pos)
							? `
						XPos: $XPos
						YPos: $YPos
						Code: $Code`
							: ''
					}

				) {
					${ImageComment}
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: payload
		});

		return data.AddItemComment;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String} xid
 */
export const getImgCommentList = async (xid, docDeliId = 0) => {
	if (isNil(xid)) return;
	try {
		const query = gql`
			query($Xid: String!, $DocDeliId: ID) {
				GetItemCommentList(Xid: $Xid, DocDeliId: $DocDeliId) {
					${ImageComment}
				}
			}
		`;

		const { data } = await apollo.query({
			query,
			variables: { Xid: xid, DocDeliId: parseInt(docDeliId, 10) },
			fetchPolicy: 'no-cache'
		});

		return data.GetItemCommentList;
	} catch (e) {
		console.log({ e });
	}
};
/**
 * @param {String} xid
 */
export const getImgCommentStats = async (xid) => {
	if (isNil(xid)) return;
	try {
		const query = gql`
			query ($Xid: String!) {
				GetItemCommentLikes(Xid: $Xid) {
					xid
					sumDoLike
					sumDoNotLike
				}
			}
		`;

		const { data } = await apollo.query({
			query,
			variables: { Xid: xid },
			fetchPolicy: 'network-only'
		});

		const res = data.GetItemCommentLikes[0];
		return res;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {!String} xid
 * @return {Array}
 */
export const deleteImg = async (xid) => {
	if (isNil(xid)) throw new Error("'DeleteImage' need a xid argument");
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($Xid: String!) {
					DeleteImage(Xid: $Xid)
				}
			`,
			variables: { Xid: xid }
		});
		return data.DeleteImage;
	} catch (e) {
		console.error(e);
	}
};
