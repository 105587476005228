var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "oodrive-sign-contract-modal",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Contract To Sign"),
        size: "xl",
        "cancel-variant": "light",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { shown: _vm.onWindowMounted },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "px-4",
                      attrs: {
                        size: "sm",
                        variant: "light",
                        disabled: _vm.loading || _vm.disabled,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "oodrive-sign animated fadeIn bg-transparent",
          class: {
            "container-layout": _vm.$screen.width >= 992,
            "container-mobile": _vm.$screen.width < 992,
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: { show: _vm.loading, opacity: "0.85" },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "text-center bg-transparent" }, [
                        _c(
                          "p",
                          [
                            _c("loading", {
                              attrs: {
                                active: _vm.loading,
                                loader: "bars",
                                color: "#00A09C",
                                width: 64,
                              },
                              on: {
                                "update:active": function ($event) {
                                  _vm.loading = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "loading-text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(3, "Please wait...")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("iframe", {
                staticClass: "full full_h clearfix",
                attrs: { src: _vm.urlFrame, frameborder: "0" },
                on: { load: _vm.onFrameLoaded },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }