<template>
	<b-modal
		id="oodrive-sign-contract-modal"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(1, 'Contract To Sign')"
		size="xl"
		cancel-variant="light"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@shown="onWindowMounted"
	>
		<div
			:class="{
				'container-layout': $screen.width >= 992,
				'container-mobile': $screen.width < 992
			}"
			class="oodrive-sign animated fadeIn bg-transparent"
		>
			<b-overlay :show="loading" opacity="0.85">
				<iframe class="full full_h clearfix" :src="urlFrame" frameborder="0" @load="onFrameLoaded" />
				<template #overlay>
					<div class="text-center bg-transparent">
						<p><loading :active.sync="loading" loader="bars" color="#00A09C" :width="64" /></p>
						<p class="loading-text">
							{{ FormMSG(3, 'Please wait...') }}
						</p>
					</div>
				</template>
			</b-overlay>
		</div>
		<template #modal-footer>
			<div class="d-flex justify-content-end align-items-center">
				<b-button size="sm" variant="light" @click="emitEventClose" class="px-4" :disabled="loading || disabled" block>
					{{ FormMSG(2, 'Close') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
// import Vue from 'vue';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import * as _ from 'lodash';
import Loading from 'vue-loading-overlay';
// import WebSocketClient from '@/plugins/websocket/WebSocketClient';
import { OODRIVE_RETURN_PAGE_TYPE } from '@/shared/constants';
import { removeDocumentSigned, waitForStatusDocumentSigned } from '@/cruds/document.crud';
// import SocketIOClient from '@/plugins/websocket/SocketIOClient';

// Vue.use(new SocketIOClient());

export default {
	name: 'OodriveSignContractModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		url: {
			type: String,
			required: false,
			default: ''
		},
		deliveryId: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		docSignedXid: {
			type: String,
			required: false,
			default: ''
		}
	},
	components: {
		Loading
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	data() {
		// https://724a0.ota.cloud.sellandsign.com/calinda/sellandsign/#/contract/5734782/sign;c_id=5734782;no_ui=true;logo=off;no_cookie=true;remote=false;refback=https:%2F%2Fwww.google.com%2F;errorback=https:%2F%2Fwww.bing.com%2F;j_token=B45388ODEhNzYtfFlDbWl0NzUraktReStGbkVYK2lPTUhmbmNPc2czTE02NjRFcTAzcHVTWW5IczJQZ1lHNjV2UzVoTnFUTDE5T3Faa1dlZUx5c0ZJdjRXTk55NVljUVRGdEhicUJRUnova3NhanNSL25pSlMxamR3K0Q2YUdWZ0lNTitmYk5zZFN1bEEzODJpbVdJbFVKN0xiSDRiUXBibVJHUDJqRnp5VGtnK2lkcUJ2RDZ1VTRjTlgzekN0R3hSTksrKzMvVnpucg
		return {
			urlFrame: '',
			loading: false,
			wsSocketClient: null,
			inc: 0,
			urlWsService: process.env.VUE_APP_WS_URL + '/documentStatus/' || 'ws://localhost:8082/socket/documentStatus/',
			disabled: false
		};
	},
	methods: {
		async emitEventClose() {
			// this.$emit('oodrive-sign-contract-modal:close');
			if (this.isDocAlreadySigned(this.docSignedXid) && this.$route.name === 'mydocuments') {
				await removeDocumentSigned(+this.deliveryId)
					.then((result) => {
						if (result === true) {
							this.$emit('oodrive-sign-contract-modal:documentUpdateStatus', {
								deliveryId: this.deliveryId
							});
							setTimeout(() => {
								this.$emit('oodrive-sign-contract-modal:close');
							}, 500);
						}
					})
					.catch((error) => {
						console.error({ error });
						setTimeout(() => {
							this.$emit('oodrive-sign-contract-modal:close');
						}, 500);
					});
			} else {
				setTimeout(() => {
					this.$emit('oodrive-sign-contract-modal:close');
				}, 500);
			}
		},
		onFrameLoaded() {
			this.loading = false;
			this.inc = this.inc + 1;
		},
		onWindowMounted() {
			this.loading = true;
		},
		onContractHandleSign() {
			this.disabled = true;
		}
	},
	watch: {
		url: {
			handler(value) {
				if (!_.isNil(value) && !_.isEmpty(value)) {
					this.urlFrame = value;
				}
			}
		},
		inc: {
			async handler(value) {
				if (value === 2) {
					const oodrivePage = localStorage.getItem('oodrive_return_page_type');
					// console.log({ oodrivePage });
					if (oodrivePage === OODRIVE_RETURN_PAGE_TYPE.SUCCESS) {
						this.loading = true;
						await waitForStatusDocumentSigned(this.deliveryId)
							.then((result) => {
								if (result === true) {
									this.$emit('oodrive-sign-contract-modal:documentUpdateStatus', {
										deliveryId: this.deliveryId
									});
								}
								setTimeout(() => {
									this.$emit('oodrive-sign-contract-modal:close');
								}, 1000);
								this.loading = false;
							})
							.catch((error) => {
								console.error({ error });
								setTimeout(() => {
									this.$emit('oodrive-sign-contract-modal:close');
								}, 1000);
								this.loading = false;
							})
							.finally(() => {
								setTimeout(() => {
									this.$emit('oodrive-sign-contract-modal:close');
								}, 1000);
								this.loading = false;
							});
					} else if (oodrivePage === OODRIVE_RETURN_PAGE_TYPE.FAILURE) {
						if (this.isDocAlreadySigned(this.docSignedXid)) {
							await removeDocumentSigned(+this.deliveryId)
								.then((result) => {
									if (result === true) {
										this.$emit('oodrive-sign-contract-modal:documentUpdateStatus', {
											deliveryId: this.deliveryId
										});
										setTimeout(() => {
											this.$emit('oodrive-sign-contract-modal:close');
										}, 1000);
									}
								})
								.catch((error) => {
									console.error({ error });
									setTimeout(() => {
										this.$emit('oodrive-sign-contract-modal:close');
									}, 1000);
								});
						} else {
							setTimeout(() => {
								this.$emit('oodrive-sign-contract-modal:close');
							}, 3000);
						}
					}
				} else if (value === 1) {
					// // Listen to Btn "Sign" of Oodrive
					// // document.querySelector("iframe").contentDocument.body.querySelector("#btn")
					// const btnSign = document
					// 	.querySelector('iframe')
					// 	.contentWindow.document.querySelector(
					// 		'#c_app > mat-drawer-container > mat-drawer-content > app-sign > div > app-footer > nav > div > div.button-container.col-right > button.mat-focus-indicator.mat-badge.mat-tooltip-trigger.mat-flat-button.mat-button-base.mat-primary.mat-badge-overlap.mat-badge-above.mat-badge-after.mat-badge-medium.mat-badge-hidden.ng-star-inserted.sign.ico.RIGHT'
					// 	);
					// // if (btnSign) {
					// btnSign.addEventListener('click', this.onContractHandleSign());
					// // }
					// // Listen to Btn "Refuse To Sign" of Oodrive
					// const btnRefuseToSign = document.getElementById(
					// 	'#c_app > mat-drawer-container > mat-drawer-content > app-sign > div > app-footer > nav > div > div.button-container.col-right > button.mat-focus-indicator.mat-badge.mat-tooltip-trigger.mat-flat-button.mat-button-base.mat-badge-overlap.mat-badge-above.mat-badge-after.mat-badge-medium.mat-badge-hidden.ng-star-inserted.nosign.ico.RIGHT'
					// );
				}
			},
			deep: true,
			immediate: true
		}
	}
	// sockets: {
	// 	connect: () => {
	// 		console.log('Socket.io connected.');
	// 	},
	// 	reply: (data) => {
	// 		console.log({ listenWs: data });
	// 	}
	// }
};
</script>
<style lang="scss">
.oodrive-sign {
	iframe {
		width: 100%;
		height: 600px;
	}
}
.loading-text {
	color: #06263e;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
}
#oodrive-sign-contract-modal___BV_modal_footer_ {
	z-index: 9999 !important;
}
</style>
